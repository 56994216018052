
export const getCalendarDates = (firstDayOfWeek: DaysOfWeek = DaysOfWeek.Sunday, monthOffset: number = 0): { firstDayOfWeek: DaysOfWeek, monthOffset: number, from: Date, to: Date } => {
    const _today: Date = new Date();
    let _from: Date = new Date();
    let _to: Date = new Date();
    const logger = console;

    const firstDay = new Date(_today.getFullYear(), _today.getMonth() + monthOffset, 1);
    const lastDay = new Date(_today.getFullYear(), _today.getMonth() + monthOffset + 1, 0);
    _from = new Date(firstDay.getTime());
    logger.debug("First day of month is :" + _from.toLocaleDateString("en-au"));
    _to = new Date(lastDay.getTime());
    logger.debug("Last day of month is :" + _to.toLocaleDateString("en-au"));

    let firstDayOffset = firstDay.getDay() - firstDayOfWeek;
    if (firstDayOffset < 0) firstDayOffset = 5 - firstDayOffset;
    logger.debug("First day of week is '" + firstDayOffset + "' days before first day");
    _from.setDate(firstDay.getDate() - firstDayOffset);

    let lastDayOffset = 6 - lastDay.getDay() + firstDayOfWeek;
    if (lastDayOffset > 6) lastDayOffset -= 7;
    logger.debug("Last day of week is '" + lastDayOffset + "' days after last day");
    _to.setDate(lastDay.getDate() + lastDayOffset);

    return {
        firstDayOfWeek: firstDayOfWeek,
        monthOffset: monthOffset,
        from: _from,
        to: _to
    };
}

export enum DaysOfWeek {
    Sunday = 0,
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6
}

export const isSameDay = (left: Date, right: Date): boolean => {
    return left.getFullYear() == right.getFullYear() &&
        left.getMonth() == right.getMonth() &&
        left.getDate() == right.getDate()
}

export const firstDayOfMonth = (value: Date, offset: number = 0) => {
    return new Date(value.getFullYear(), value.getMonth() + offset, 1);
}


export const lastDayOfMonth = (value: Date, offset: number = 0) => {
    return new Date(value.getFullYear(), value.getMonth() + offset + 1, 0);
}

export const monthName = (value: Date, offset: number = 0) => {
    const firstDay = firstDayOfMonth(value, offset);
    return firstDay.toLocaleDateString('default', { month: 'long' });
}

export const yearName = (value: Date, offset: number = 0) => {
    const firstDay = firstDayOfMonth(value, offset);
    return firstDay.toLocaleDateString('default', { year: 'numeric' });
}