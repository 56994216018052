import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import theme from 'styles/theme';
import { GlobalState } from './src/utils/globalState';

export const wrapRootElement = ({ element }) => {
 return (
  <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalState>
            {element}
        </GlobalState>
  </ThemeProvider>
 );
};
