import React, { useState, useMemo } from "react"
import { IArea, IMediaItem } from "../../services/CmsTypes";


export interface MediaItemListData {
    searchTerm: string;
    selectedArea: IArea;
    rows: any[];
    page: number;
    onSearchTermChange: (term: string) => void;
    onAreaChange: (area: IArea) => void;
    updateRows: (items: IMediaItem[]) => void;
    changePage: (pageNumber: number) => void;
};

export const mediaItemListDefaultValue: MediaItemListData = {
    searchTerm: "",
    selectedArea: null,
    rows: [],
    page: 1,
    onSearchTermChange: () => null,
    onAreaChange: () => null,
    updateRows: () => null,
    changePage: () => null,
};

export const MediaItemListContext = React.createContext<MediaItemListData>(mediaItemListDefaultValue);

export const useMediaItemListValue = (): MediaItemListData => {
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [selectedArea, setSelectedArea] = useState<IArea>(null);
    const [rows, setRows] = useState([])
    const [page, setPage] = useState(1);

    const onSearchTermChange = (term: string) => {
        setSearchTerm(term);
    };

    const onAreaChange = (area: IArea) => {
        setSelectedArea(area);
    };

    const updateRows = (items: IMediaItem[]) => {
        setRows(items.map(resource => ({ ...resource, id: resource.itemId })))
        setPage(1);
    };

    const changePage = (pageNumber: number) => {
        setPage(pageNumber);
    }

    return useMemo(() => ({
        searchTerm,
        selectedArea,
        rows,
        page,
        onSearchTermChange,
        onAreaChange,
        updateRows,
        changePage,
    }), [
        searchTerm,
        selectedArea,
        rows,
        page,
        onSearchTermChange,
        onAreaChange,
        updateRows,
        changePage,
    ]);
}
