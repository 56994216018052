import { ICalendarSummary } from "@intalex/planning-types";
import { DaysOfWeek, getCalendarDates } from '../dateHelper';
import React, { useState, useMemo } from "react"

export interface IEventsRange {
    firstDayOfWeek: DaysOfWeek,
    monthOffset: number,
    from: Date,
    to: Date
}

export interface CalendarEventListData {
    searchTerm: string;
    selectedCalendar: ICalendarSummary;
    eventsRange: IEventsRange;
    onSearchTermChange: (term: string) => void;
    onCalendarChange: (calendar: ICalendarSummary) => void;
    changeRange: (range: IEventsRange) => void;
};

export const contentItemListDefaultValue: CalendarEventListData = {
    searchTerm: "",
    selectedCalendar: null,
    eventsRange: getCalendarDates(),
    onSearchTermChange: () => null,
    onCalendarChange: () => null,
    changeRange: (range: IEventsRange) => null
};

export const CalendarEventListContext = React.createContext<CalendarEventListData>(contentItemListDefaultValue);

export const useCalendarEventListValue = (): CalendarEventListData => {
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [selectedCalendar, setSelectedCalendar] = useState<ICalendarSummary>(null);
    const [eventsRange, setEventsRange] = useState(getCalendarDates());

    const onSearchTermChange = (term: string) => {
        setSearchTerm(term);
    };

    const onCalendarChange = (calendar: ICalendarSummary) => {
        // setEventsRange(getCalendarDates());
        setSelectedCalendar(calendar);
    };

    const changeRange = (range: IEventsRange) => {
        setEventsRange(range);
    }

    return useMemo(() => ({
        searchTerm,
        selectedCalendar,
        eventsRange,
        onSearchTermChange,
        onCalendarChange,
        changeRange,
    }), [
        searchTerm,
        selectedCalendar,
        selectedCalendar,
        eventsRange,
        onSearchTermChange,
        onCalendarChange,
        changeRange,
    ]);
}
