import React, { useState, useMemo } from "react"
import { IArea } from "../../services/CmsTypes";

export interface SelectedAreaData {
    selectedArea: IArea;
    onAreaChange: (area: IArea) => void;
};

export const selectedAreaDefaultValue: SelectedAreaData = {
    selectedArea: null,
    onAreaChange: () => null,
};

export const SelectedAreaContext = React.createContext<SelectedAreaData>(selectedAreaDefaultValue);

export const useSelectedAreaValue = (): SelectedAreaData => {
    const [selectedArea, setSelectedArea] = useState<IArea>(null);

    const onAreaChange = (area: IArea) => {
        setSelectedArea(area);
    };

    return useMemo(() => ({
        selectedArea,
        onAreaChange,
    }), [
        selectedArea,
        onAreaChange,
    ]);
}