import { ICalendarSummary } from "@intalex/planning-client";
import React, { useState, useMemo } from "react"

export interface SelectedCalendarData {
    selectedCalendar: ICalendarSummary;
    onCalendarChange: (calendar: ICalendarSummary) => void;
};

export const selectedCalendarDefaultValue: SelectedCalendarData = {
    selectedCalendar: null,
    onCalendarChange: () => null,
};

export const SelectedCalendarContext = React.createContext<SelectedCalendarData>(selectedCalendarDefaultValue);

export const useSelectedCalendarValue = (): SelectedCalendarData => {
    const [selectedCalendar, setSelectedCalendar] = useState<ICalendarSummary>(null);

    const onCalendarChange = (calendar: ICalendarSummary) => {
        setSelectedCalendar(calendar);
    };

    return useMemo(() => ({
        selectedCalendar,
        onCalendarChange,
    }), [
        selectedCalendar,
        onCalendarChange,
    ]);
}