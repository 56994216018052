import React, { useState, useMemo } from "react"

type ContentTabs = string;

export interface SelectedTabData {
    selectedTab: ContentTabs;
    onTabChange: (tab: string) => void;
};

export const selectedTabDefaultValue: SelectedTabData = {
    selectedTab: 'Items',
    onTabChange: () => null,
};

export const SelectedTabContext = React.createContext<SelectedTabData>(selectedTabDefaultValue);

export const useSelectedTabValue = (): SelectedTabData => {
    const [selectedTab, setSelectedTab] = useState<ContentTabs>('Items');

    const onTabChange = (tab: ContentTabs) => {
        setSelectedTab(tab);
    };

    return useMemo(() => ({
        selectedTab,
        onTabChange,
    }), [
        selectedTab,
        onTabChange,
    ]);
}