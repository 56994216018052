import React from "react"
import { useCalendarEventListValue, CalendarEventListContext } from "./calendarEventListContext";
import { useContentItemListValue, ContentItemListContext } from './contentItemListContext';
import { useMediaItemListValue, MediaItemListContext } from './mediaItemListContext';
import { useSelectedAreaValue, SelectedAreaContext } from './selectedAreaContext';
import { useSelectedCalendarValue, SelectedCalendarContext } from './selectedCalendarContext';
import { useSelectedTabValue, SelectedTabContext } from './selectedTabContext';


const GlobalState = ({ children }) => {
    const contentItemListContextValue = useContentItemListValue();
    const calendarEventListContextValue = useCalendarEventListValue();
    const selectedAreaContextValue = useSelectedAreaValue();
    const selectedCalendarContextValue = useSelectedCalendarValue();
    const mediaItemListContextValue = useMediaItemListValue();
    const selectedTabContextValue = useSelectedTabValue()

    return (
        <ContentItemListContext.Provider value={contentItemListContextValue}>
            <CalendarEventListContext.Provider value={calendarEventListContextValue}>
                <SelectedAreaContext.Provider value={selectedAreaContextValue}>
                    <SelectedCalendarContext.Provider value={selectedCalendarContextValue}>
                        <MediaItemListContext.Provider value={mediaItemListContextValue}>
                            <SelectedTabContext.Provider value={selectedTabContextValue}>
                                {children}
                            </SelectedTabContext.Provider>
                        </MediaItemListContext.Provider>
                    </SelectedCalendarContext.Provider>
                </SelectedAreaContext.Provider>
            </CalendarEventListContext.Provider>
        </ContentItemListContext.Provider>
    )
};

export default GlobalState;