const palette = {
    background: {
        default: '#fff',
    },
    text: {
        primary: '#007098',
        secondary: '#17252A',
    },
    primary: {
        light: '#17252A',
        main: '#007098',
        dark: '#17252A',
        contrastText: '#fff',
    },
    secondary: {
        light: '#6affff',
        main: '#3AAFA9',
        dark: '#00a7bc',
        contrastText: '#fff',
    },
};

export default palette;
