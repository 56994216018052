import React, { useState, useMemo } from "react"
import { IArea, IContentType } from "../../services/CmsTypes";
import { Tenant } from "../tenant";

export interface ContentItemListData {
    searchTerm: string;
    selectedArea: IArea;
    // selectedTenant: Tenant;
    selectedContentType: IContentType;
    page: number;
    pageLink: string;
    onSearchTermChange: (term: string) => void;
    onAreaChange: (area: IArea) => void;
    // onTenantChange: (tenant: Tenant) => void;
    onContentTypeChange: (contentType: IContentType) => void;
    changePage: (pageNumber: number) => void;
    storePageLink: (link: string) => void;
};

export const contentItemListDefaultValue: ContentItemListData = {
    searchTerm: "",
    selectedArea: null,
    selectedContentType: null,
    page: 1,
    pageLink: "",
    onSearchTermChange: () => null,
    onAreaChange: () => null,
    onContentTypeChange: () => null,
    changePage: () => null,
    storePageLink: () => null,
    // selectedTenant: null,
    // onTenantChange: () => null
};

export const ContentItemListContext = React.createContext<ContentItemListData>(contentItemListDefaultValue);

export const useContentItemListValue = (): ContentItemListData => {
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [selectedArea, setSelectedArea] = useState<IArea>(null);
    // const [selectedTenant, setSelectedTenant] = useState<Tenant>(null);
    const [selectedContentType, setSelectedContentType] = useState<IContentType>(null);
    const [page, setPage] = useState(1);
    const [pageLink, setPageLink] = useState<string>("");

    const onSearchTermChange = (term: string) => {
        setPageLink("");
        setPage(1);
        setSearchTerm(term);
    };

    const onAreaChange = (area: IArea) => {
        setPageLink("");
        setPage(1);
        setSelectedArea(area);
        setSelectedContentType(null);
    };

    // const onTenantChange = (tenant: Tenant) => {
    //     if (tenant?.tenantId === selectedTenant?.tenantId) return;
    //     onAreaChange(null);
    //     setSelectedTenant(tenant);
    // };

    const onContentTypeChange = (contentType: IContentType) => {
        setPageLink("");
        setPage(1);
        setSelectedContentType(contentType);
    };

    const changePage = (pageNumber: number) => {
        setPage(pageNumber);
    }

    const storePageLink = (link: string) => {
        setPageLink(link);
    }

    return useMemo(() => ({
        searchTerm,
        selectedArea,
        // selectedTenant,
        selectedContentType,
        page,
        pageLink,
        onSearchTermChange,
        onAreaChange,
        // onTenantChange,
        onContentTypeChange,
        changePage,
        storePageLink,
    }), [
        searchTerm,
        selectedArea,
        // selectedTenant,
        selectedContentType,
        page,
        pageLink,
        onSearchTermChange,
        onAreaChange,
        // onTenantChange,
        onContentTypeChange,
        changePage,
        storePageLink,
    ]);
}
