import muiTheme from './muiTheme';
import '@material-ui/core/styles/createTheme';
import { createTheme } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles';

//Add lightest and darkest options to colour palette
declare module '@material-ui/core/styles/createPalette' {
    interface PaletteColor {
        lightest?: string;
        darkest?: string;
        hover?: string;
    }
    interface SimplePaletteColorOptions {
        lightest?: string;
        darkest?: string;
        hover?: string;
    }
}

//Add button as a new colour palette
declare module "@material-ui/core/styles/createPalette" {
    interface Palette {
        button: Palette['primary'];
        buttonSecondary: Palette['primary'];
    }
    interface PaletteOptions {
        button: PaletteOptions['primary'];
        buttonSecondary: PaletteOptions['primary'];
    }
}

const theme = {
  ...muiTheme,
  app: {
    maxWidth: muiTheme.typography.pxToRem(960),
  },
};

export const theme_grey = createTheme({
    typography: {
        fontFamily: 'Roboto',
        fontSize: 20,
    },
    palette: {
        primary: {
            lightest: "	#eeeeee",
            light: "#dddddd",
            main: "#cccccc",
            dark: "#bbbbbb",
            darkest: "#9a9a9a",
        },
        secondary: {
            main: "#8a2628",
        },
        button: {
            main: "##9a9a9a",
            contrastText: "#ffffff",
            hover: alpha("##9a9a9a", 0.7),
        },
        buttonSecondary: {
            main: "#ffffff",
            contrastText: "#000",
            hover: alpha("##9a9a9a", 0.7),
        },
        text: {
            primary: "#fff",
            secondary: "#000",
        }
    },
    spacing: 8,
});

export default theme;
